import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import ContainerImg from '../../components/ContainerImg/ContainerImg'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import TextBlock from '../../components/TextBlock/TextBlock'
import List from '../../components/List/List'
import TwoColumns from '../../components/TwoColumns/TwoColumns'
import WorkWithUs from '../../components/WorkWithUs/WorkWithUs'
import BottomNav from '../../components/BottomNav/BottomNav'
import Intro from '../../components/Intro/Intro'
import Goals from '../../components/Goals/Goals'
import OurApproach from '../../components/OurApproach/OurApproach'

const Consulting = () => {
  const businessFilars = [
    {
      id: 1,
      text: 'People',
    },
    {
      id: 2,
      text: 'Products',
    },
    {
      id: 3,
      text: 'Processes',
    },
    {
      id: 4,
      text: 'Sales',
    },
    {
      id: 5,
      text: 'Marketing',
    },
  ]

  const helpYouWith = [
    {
      id: 11,
      text: 'Organize and automate inefficient processes',
    },
    {
      id: 21,
      text: 'Increase revenue by cutting unnecessary costs',
    },
    {
      id: 31,
      text: 'Strengthen online-security and reduce related risks',
    },
    {
      id: 41,
      text: 'Create a better customer experience',
    },
    {
      id: 51,
      text: 'Give more value to employees (increased retention)',
    },
    {
      id: 61,
      text: 'Run your business in a less stressful way',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Business Consulting"
        keywords={[
          `Emersoft`,
          `business`,
          `consulting`,
          `London`,
          `digital agency`,
        ]}
      />

      <SectionHeader>Focus on making money and automate the rest</SectionHeader>
      <Intro paragraph1="How much time and money does it cost your business to run on inefficient processes?" />
      <ContainerImg
        img="https://cdn.emersoft.co/emersoft-website/consulting/emersoft-team-consulting.jpg"
        alt="Marcin and Michael"
      />
      <TextBlock
        heading="Processes"
        paragraph1="Smooth, optimized processes are the lifeblood of every organization. They lay the groundwork for future expansion, eliminate the guesswork and decrease the risk of suffering financial losses."
        paragraph2="So If you lack the “know-how” of what tools and software to use to remove the most repetitive and manual tasks that kill your company’s efficiency..."
      />
      <TwoColumns
        heading="Tools"
        paragraph1="Let us show you tools to put your workflows on auto-pilot and free up time and money you can invest in growing your revenue."
        paragraph3="When optimized— they lay the groundwork for future expansion, eliminate the guesswork and decrease the risk of suffering financial losses."
      />
      <Intro paragraph1="In our 90-minute session (valued at  $997), we’ll audit your workflows focusing on the following areas:" />
      <List data={businessFilars} additionalClass="list-section--bg" />
      <Goals title="We’ll help you:" goalsList={helpYouWith} />
      <OurApproach
        title="Why you should trust us"
        paragraph1="You get to work with experts who contributed to the growth of internationally renowned companies like Canon and LightSpeed VT or celebrities like Robert Burneika or Brad Lea."
      />
      <WorkWithUs
        title="If you’re ready to remove hidden bottlenecks preventing your business from running seamlessly…"
        ctaText="Let's chat"
      />
      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/services/websites"
        firstHeading="Websites/Design"
        secondLink="/services/hire-developers"
        secondHeading="Supporting Services"
      />
    </Layout>
  )
}

export default Consulting
